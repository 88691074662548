<template>
  <footer class="py-3 footer">
    <div class="container-fluid">
      <!-- <div class="row align-items-center justify-content-end"> -->
        <div class="mb-4 mb-lg-0">
          <div class="text-sm copyright text-muted text-end">
            ©
            {{ new Date().getFullYear() }}, powered by
            <a class="font-weight-bold" target="_blank">Churchscheduling</a>
          </div>
        </div>
        <!-- <div class="col-lg-6">
          <ul class="nav nav-footer justify-content-center justify-content-lg-end">
            <li class="nav-link text-muted">
              Churchscheduling
            </li>
            <li class="nav-link text-muted">
              About Us
            </li>
            <li class="nav-link text-muted">
              Blog
            </li>
            <li class="nav-link text-muted">
              License
            </li>
          </ul>
        </div> -->
      <!-- </div> -->
    </div>
  </footer>
</template>

<script>
export default {
  name: "app-footer",
};
</script>
