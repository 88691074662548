<template>
    <ContentContainer>
        <template v-slot:content>
            <!-- <div class="row d-flex justify-content-center gap-1"> -->
            <div class="row d-flex m-auto">
                <div v-for="item in menuData.admintools" :key="item.label" class="col-lg-4 col-md-5 col-12">
                    <TabCard :text="item.label" @click="openModal" />
                </div>
            </div>

            <Modal v-if="isModalOpen" :modalHeading="modalHeading" @close="closeModal">
            </Modal>
        </template>
    </ContentContainer>
</template>

<script>
import Modal from '@/components/Modal/Modal.vue';
import ContentContainer from '@/components/PageLayout/ContentContainer.vue';
import TabCard from '@/components/Cards/TabCards.vue';
import { mapState } from 'vuex';

export default {
    name: "admintools",
    data() {
        return {
            isModalOpen: false,
            modalHeading: "",
        };
    },
    components: {
        ContentContainer,
        TabCard,
        Modal
    },
    computed: {
        ...mapState([
            "menuData"
        ]),
    },
    methods: {
        openModal() {
            this.isModalOpen = true;
            // this.modalHeading = item.label; // Set heading based on clicked item
        },
        closeModal() {
            this.isModalOpen = false;
        },
    },
};
</script>