<!-- <template>

  <select class="px-4 py-2 mx-3 border rounded text-dark">
    <option selected disabled>Select Organization</option>
    <option value="1"> CS Test Church </option>
  </select>

  <select class="px-4 py-2 mx-3 border text-dark">
    <option selected disabled>Select Location</option>
    <option value="1">Chicago main Branch</option>
    <option value="2">Alpharetta Branch</option>
  </select>

</template>

<script>

import { mapState, mapActions } from "vuex";

export default {
  props: ["dropdownList"],
  data() {
    return {
      organizations: [],
      locations: []
    }
  },
  methods: {
    ...mapActions(['fetchDashboardData']),
    setDropDownList() {
      // const dropDownOptions = this.dashboardData?.orgs_locations_menu?.list
      // this.organizations = Object.keys(dropDownOptions)
      // this.locations = dropDownOptions[this.organizations]?.location_name

      // console.log("organizations", this.organizations);
      // console.log("locations", this.locations);
    }
  },
  computed: {
    ...mapState([
      "dashboardData",
    ])
  },
  async created() {
    this.setDropDownList();
    this.fetchDashboardData();
  }
}
</script>

<style>
  select {
    background-color: inherit;
    outline: none;
  }
</style> -->


<!-- <template>
    <div class="dropdown">
      <button class="btn btn-default dropdown-toggle" type="button" data-toggle="dropdown" @click="toggleDropdown">
        Dropdown
        <span class="caret"></span>
      </button>
      <ul class="dropdown-menu" :class="{ show: isDropdownOpen }">
        <li v-for="item in menuItems" :key="item.name">
          <a class="dropdown-item" v-if="!item.children" @click="setSelectedItem(item)">{{ item.name }}</a>
          <a class="dropdown-item" v-if="item.children" @click.stop="toggleSubmenu(item)">
            {{ item.name }}
            <span class="caret"></span>
          </a>
          <ul class="dropdown-menu" v-if="item.children && isSubmenuOpen[item.name]" :class="{ show: isSubmenuOpen[item.name] }">
            <li v-for="subitem in item.children" :key="subitem.name">
              <a class="dropdown-item" @click="setSelectedItem(subitem)">{{ subitem.name }}</a>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        isDropdownOpen: false,
        isSubmenuOpen: {}, // Object to store open/closed state of submenus
        menuItems: [
          { name: 'Item 1' },
          { name: 'Item 2', children: [
            { name: 'Subitem 1' },
            { name: 'Subitem 2' },
          ]},
          { name: 'Item 3' },
        ],
        selectedDropdown: null,
      };
    },
    methods: {
      toggleDropdown() {
        this.isDropdownOpen = !this.isDropdownOpen;
      },
      toggleSubmenu(item) {
        this.isSubmenuOpen[item.name] = !this.isSubmenuOpen[item.name];
      },
      setSelectedItem(item) {
        this.selectedDropdown = item.name;
      },
    },
  };
  </script>
  
  <style scoped>
  </style> -->


<!-- <template>
    <div>
      <b-dropdown id="dropdown-1" text="Dropdown Button 1" class="m-md-2">
        <b-dropdown-item>First Action 1</b-dropdown-item>
        <b-dropdown-item>Second Action 1</b-dropdown-item>
        <b-dropdown id="dropdown-2" text="Dropdown Button 2" class="m-md-2">
          <b-dropdown-item>First Action 2</b-dropdown-item>
          <b-dropdown-item>Second Action 2</b-dropdown-item>
        </b-dropdown>
      </b-dropdown>
    </div>
  </template> -->

<!-- <script>
export default {
  // Your component logic here
};
</script> -->

<!-- <template>
  <div class="container">
    <div class="">
      <button class="btn btn-default dropdown-toggle" type="button" @click="toggleDropdown">
        Tutorials
        <span class="caret"></span>
      </button>
      <ul class="dropdown-menu" v-show="isDropdownOpen">
        <li><a tabindex="-1" href="#">HTML</a></li>
        <li><a tabindex="-1" href="#">CSS</a></li>
        <li class="dropdown-submenu">
          <a class="test" tabindex="-1" href="#" @click="toggleSubmenu($event)">New dropdown <span class="caret"></span></a>
          <ul class="dropdown-menu">
            <li><a tabindex="-1" href="#">2nd level dropdown</a></li>
            <li><a tabindex="-1" href="#">2nd level dropdown</a></li>
            <li class="dropdown-submenu">
              <a class="test" href="#" @click="toggleSubmenu($event)">Another dropdown <span class="caret"></span></a>
              <ul class="dropdown-menu">
                <li><a href="#">3rd level dropdown</a></li>
                <li><a href="#">3rd level dropdown</a></li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isDropdownOpen: false
    };
  },
  methods: {
    toggleDropdown() {
      this.isDropdownOpen = !this.isDropdownOpen;
    },
    toggleSubmenu(event) {
      event.preventDefault();
      event.stopPropagation();
      const submenu = event.target.nextElementSibling;
      if (submenu) {
        submenu.style.display = submenu.style.display === 'block' ? 'none' : 'block';
      }
    }
  }
};
</script>

<style scoped>
.dropdown-submenu {
  position: relative;
}

.dropdown-submenu .dropdown-menu {
  top: 0;
  left: 100%;
  margin-top: -1px;
}
</style> -->


<!-- <template>
  <ul class="dropdown">
    <li class="dropdown-item" v-for="item in options" :key="item.text">
      <a href="#" @click="showSubmenu(item)">
        {{ item.text }}
      </a>
      <ul id="dropdownItem" class="sub-dropdown" v-if="item.subOptions" @mouseleave="hideSubmenu">
        <li class="sub-dropdown-item" v-for="subItem in item.subOptions" :key="subItem.text">
          <a href="#" @click="showNestedSubmenu(subItem)">
            {{ subItem.text }}
          </a>
          <ul class="nested-sub-dropdown" v-if="subItem.subOptions" @mouseleave="hideSubmenu">
            <li v-for="nestedItem in subItem.subOptions" :key="nestedItem.text">
              <a href="#">{{ nestedItem.text }}</a>
            </li>
          </ul>
        </li>
      </ul>
    </li>
  </ul>
</template>

<script>
export default {
  data() {
    return {
      options: [
        {
          text: 'Option 1', subOptions: [
            {
              text: 'Suboption 1.1', subOptions: [
                { text: 'Nested Suboption 1.1.1' },
                { text: 'Nested Suboption 1.1.2' },
                { text: 'Nested Suboption 1.1.3' },
              ]
            },
            { text: 'Suboption 1.2' },
            { text: 'Suboption 1.3' },
          ]
        },
      ],
    };
  },
  methods: {
    showSubmenu(item) {
      item.showSubmenu = true;
    },
    hideSubmenu() {
      this.options.forEach(item => item.showSubmenu = false);
    },
    showNestedSubmenu(item) {
      item.showNestedSubmenu = false;
    },
  },  
};
</script>

<style scoped>

.dropdown {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  border: 2px solid black;
}

.dropdown-item {
  position: relative;
}

.dropdown-item a {
  text-decoration: none;
  display: block;
}

.sub-dropdown,
.nested-sub-dropdown {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  list-style-type: none;
  padding: 10px;
  margin: 0;
  border: 2px solid black;
  background-color: white;
}

.sub-dropdown-item {
  position: relative;
}

.sub-dropdown-item:hover .nested-sub-dropdown {
  display: block;
  left: 100%;
  top: 0;
}

.dropdown-item:hover .sub-dropdown {
  display: block;
}

</style> -->



<!-- <template>
  <div>
    <select v-model="selectedOption" @change="handleSelect">
      <option v-for="item in options" :key="item.text" :value="item.text">
        {{ item.text }}
      </option>
    </select>
    <select v-if="selectedOption && subOptions.length" v-model="selectedSubOption" @change="handleSubSelect">
      <option v-for="subItem in subOptions" :key="subItem.text" :value="subItem.text">
        {{ subItem.text }}
      </option>
    </select>
    <select v-if="selectedSubOption && nestedSubOptions.length" v-model="selectedNestedSubOption">
      <option v-for="nestedItem in nestedSubOptions" :key="nestedItem.text" :value="nestedItem.text">
        {{ nestedItem.text }}
      </option>
    </select>
  </div>
</template>

<script>
export default {
  data() {
    return {
      options: [
        {
          text: 'Option 1', subOptions: [
            {
              text: 'Suboption 1.1', subOptions: [
                { text: 'Nested Suboption 1.1.1' },
                { text: 'Nested Suboption 1.1.2' },
                { text: 'Nested Suboption 1.1.3' },
              ]
            },
            { text: 'Suboption 1.2' },
            { text: 'Suboption 1.3' },
          ]
        },
      ],
      selectedOption: null,
      selectedSubOption: null,
      selectedNestedSubOption: null,
      subOptions: [],
      nestedSubOptions: []
    };
  },
  methods: {
    handleSelect() {
      const selected = this.options.find(option => option.text === this.selectedOption);
      this.subOptions = selected && selected.subOptions ? selected.subOptions : [];
      this.selectedSubOption = null;
      this.selectedNestedSubOption = null;
      this.nestedSubOptions = [];
    },
    handleSubSelect() {
      const selected = this.subOptions.find(subOption => subOption.text === this.selectedSubOption);
      this.nestedSubOptions = selected && selected.subOptions ? selected.subOptions : [];
      this.selectedNestedSubOption = null;
    }
  }
};
</script>

<style scoped>
/* Optional: Add styles for the select inputs */
select {
  margin: 10px;
  padding: 5px;
  border: 2px solid black;
  background-color: white;
}
</style> -->


<template>
  <ul class="dropdown">
    <li class="dropdown-item">
      <a href="#" @click.prevent="toggleDropdown">
        <span class="d-flex justify-content-between align-items-center"> {{ selectedValue }} <i class="fas fa-angle-down"></i> </span>
      </a>
      <ul v-for="item in options" :key="item.text" @mouseleave="hideDropdown">
        <ul class="sub-dropdown" v-if="item.subOptions">
          <li class="sub-dropdown-item" v-for="subItem in item.subOptions" :key="subItem.text">
            <a href="#">
              {{ subItem.text }}
            </a>
            <ul class="nested-sub-dropdown" v-if="subItem.subOptions">
              <li v-for="nestedItem in subItem.subOptions" :key="nestedItem.text">
                <a href="#" @click="selectItem(nestedItem)">{{ nestedItem.text }}</a>
              </li>
            </ul>
          </li>
        </ul>
      </ul>

    </li>
  </ul>
</template>

<script>
export default {
  data() {
    return {
      options: [
        {
          text: 'CS Test Church', subOptions: [
            {
              text: 'CS Test Church', subOptions: [
                { text: 'Chicago main Branch' },
                { text: 'Alpharetta Branch' }
              ]
            },
            // { text: 'CS Test Church' },
            // { text: 'CS Test Church' },
          ]
        },
      ],
      selectedValue: 'CS Test Church',
      isDropdownVisible: false,
    };
  },
  methods: {
    toggleDropdown() {
      this.isDropdownVisible = !this.isDropdownVisible;
    },
    hideDropdown() {
      this.isDropdownVisible = false;
    },
    selectItem(item) {
      this.selectedValue = item.text;
      this.hideDropdown();
    },
  },
};
</script>

<style scoped>
.dropdown {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  border: 1px solid gray;
  border-radius: 3px;
  width: 200px;
}

.dropdown-item {
  position: relative;
}

.dropdown-item a {
  text-decoration: none;
  display: block;
}

.sub-dropdown,
.nested-sub-dropdown {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  list-style-type: none;
  padding: 10px;
  margin: 0;
  border: 1px solid gray;
  background-color: white;
  width: 200px;
  border-radius: 4px;
}

.sub-dropdown-item {
  position: relative;
}

.sub-dropdown-item:hover .nested-sub-dropdown {
  display: block;
  left: 100%;
  top: 0;
}

.dropdown-item:hover .sub-dropdown {
  display: block;
}
</style>