<template>
  <sidenav :custom_class="color" :class="[isRTL ? 'fixed-end' : 'fixed-start']" v-if="showSidenav" />
  <main class="main-content position-relative max-height-vh-100 h-100 overflow-x-hidden">
    <!-- nav -->
    <navbar :class="[isNavFixed ? navbarFixed : '', isAbsolute ? absolute : '']"
      :color="isAbsolute ? 'text-white opacity-8' : ''" :minNav="navbarMinimize" v-if="showNavbar" />
    <Qoute />
    <GoogleAds />
    <router-view />
    <app-footer v-show="showFooter" />
    <!-- <configurator :toggle="toggleConfigurator" :class="[showConfig ? 'show' : '', hideConfigButton ? 'd-none' : '']" /> -->
  </main>
</template>
<script>
import Sidenav from "./components/Sidenav";
// import Configurator from "@/components/Configurator.vue";
import Navbar from "@/components/Navbars/Navbar.vue";
import AppFooter from "@/components/Footer/Footer.vue";
import { mapMutations, mapState, mapActions } from "vuex";
import GoogleAds from "./components/GoogleAds/GoogleAds.vue";
import Qoute from "./components/Qoutes/Qoute.vue";

export default {
  name: "App",
  components: {
    Sidenav,
    // Configurator,
    Navbar,
    AppFooter,
    GoogleAds,
    Qoute
  },
  methods: {
    ...mapMutations(["toggleConfigurator", "navbarMinimize", "SET_MENU_DATA"]),
    ...mapActions(['fetchMenuData', 'fetchDashboardData']),
  },
  computed: {
    ...mapState([
      "isRTL",
      "color",
      "isAbsolute",
      "isNavFixed",
      "navbarFixed",
      "absolute",
      "showSidenav",
      "showNavbar",
      "showFooter",
      "showConfig",
      "hideConfigButton",
      "menuData",
      "dashboardData",
    ]),
  },
  beforeMount() {
    this.$store.state.isTransparent = "bg-transparent";

    const sidenav = document.getElementsByClassName("g-sidenav-show")[0];

    if (window.innerWidth > 1200) {
      sidenav.classList.add("g-sidenav-pinned");
    }
  },
  async created() {
    this.fetchMenuData();
    this.fetchDashboardData();
  }
};
</script>
