<template>


    <div class="card p-4 m-2" @click="openModal">
        {{ text }}
    </div>


    <!-- <div class="" v-if="isModalOpened">
        <div class="modal-overlay">
            <div class="">
                <div class="">
                    <button @click="closeModal">X</button>
                </div>
                <span class=""> props.modalheading </span>
                <hr class="my-4" />
                <p> props.modalContent </p>
            </div>
        </div>
    </div> -->

</template>

<script>
import Modal from '@/components/Modal/Modal.vue';

export default {
    data() {
        return {
            isModalOpened: false
        }
    },
    props: [
        'text'
    ],
    component: {
        Modal
    },
    methods: {
        openModal: function () {
            this.isModalOpened = true;
        },
        closeModal: function () {
            this.isModalOpened = false;
        }
    }
}

</script>

<style>
.modal-overlay {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /* background-color: rgba(0, 0, 0, 0.5); */
}
</style>
