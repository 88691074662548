<template>
    <div class="container-fluid">
        <div style="background-color: #38383E;" class="text-white text-center p-2 my-2">
            <p>"With great power the apostles continued to testify to the resurrection of the Lord Jesus. And God's
                grace
                was so powerfully at work in them all."
            </p>
            <footer class="blockquote-footer text-white text-right"><cite title="Source Title"> Acts 4:33</cite>
            </footer>
        </div>
    </div>
</template>