<template>
    <div class="modal-container">
        <div class="modal-overlay" @click.stop="closeModal">
            <div class="modal-content">
                <div class="modal-header">
                    <span> {{ modalHeading }} </span>
                    <button class="modal-close-button" @click="closeModal">X</button>
                </div>
                <div class="modal-body">
                    <!-- <slot /> -->
                </div>
                <div class="modal-footer">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: [
        "modalHeading"
    ],
    methods: {
        closeModal() {
            this.$emit('close');
        },
    },
};
</script>

<style scoped>
.modal-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-overlay {
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-content {
    background-color: #fff;
    padding: 20px;
    border-radius: 5px;
}

.modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

/* .modal-body {
    Add styles for the modal body content
}

.modal-footer {
    Add styles for the modal footer content
} */

.modal.active {
    display: block;
    /* Show the modal when active */
}

.modal-close-button {
    border: 0;
    outline: none;
}

@media(min-width: 769px) {
    .modal-overlay {
        padding-left: 20%;
    }
}
</style>