<!-- <template>
    <div class="">
        <div>
            <img src="../../assets/img/Facebook-Ad-Examples.png" class="google-ads-image" style="" />
            <div>
                <p class="d-flex justify-content-end">google ads</p>
            </div>
        </div>
    </div>

    <div class="container-fluid mt-4">
        <div class="row align-items-center">
            <div>
                <img src="../../assets/img/Facebook-Ad-Examples.png" class="google-ads-image" style="" />
                <div>
                    <p class="d-flex justify-content-end">google ads</p>
                </div>
            </div>
        </div>
    </div>
</template> -->

<template>
    <div class="container-fluid">
        <div class="page-header min-height-300 border-radius-xl mt-4"
            style="
        background-image: url('https://images.unsplash.com/photo-1531512073830-ba890ca4eba2?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1920&q=80');">
            <!-- <span class="mask bg-gradient-success opacity-6"></span> -->
        </div>
    </div>
</template>