import { createRouter, createWebHistory } from "vue-router";
import Dashboard from "../views/Dashboard.vue";
import Tables from "../views/Tables.vue";
import Billing from "../views/Billing.vue";
import RTL from "../views/Rtl.vue";
import Notifications from "../views/Notifications.vue";
import Profile from "../views/Profile.vue";
import SignIn from "../views/SignIn.vue";
import SignUp from "../views/SignUp.vue";
// import AdminMenu from "../views/AdminMenu.vue";
import UserMenu from "../views/UserMenu.vue";
import MyAssignments from "../views/MyAssignments.vue";
import Organizations from "../views/Organizations.vue";
import AdminTool from "../views/AdminTool.vue";
import Extras from "../views/Extras.vue";
import MessageTracker from "../views/MessageTracker.vue";
import Setup from "../views/Setup.vue";

const routes = [
  {
    path: "/",
    name: "/",
    redirect: "/admin",
  },
  {
    path: "/admin",
    name: "Admin",
    component: Dashboard
},
  {
    path: "/tables",
    name: "Tables",
    component: Tables,
  },
  {
    path: "/billing",
    name: "Billing",
    component: Billing,
  },
  {
    path: "/rtl-page",
    name: "RTL",
    component: RTL,
  },
  {
    path: "/notifications",
    name: "Notifications",
    component: Notifications,
  },
  {
    path: "/profile",
    name: "Profile",
    component: Profile,
  },
  {
    path: "/sign-in",
    name: "SignIn",
    component: SignIn,
  },
  {
    path: "/sign-up",
    name: "SignUp",
    component: SignUp,
  },

  //Modal dashboard routes 
  // {
  //   path: '/admin-menu',
  //   name: 'adminmenu',
  //   component: AdminMenu
  // },
  {
    path: '/volunteer',
    name: 'volunteer',
    component: UserMenu
  },
  {
    path: '/my-assignments',
    name: 'myassignments',
    component: MyAssignments
  },
  {
    path: '/organizations',
    name: 'organizations',
    component: Organizations
  },
  {
    path: '/admin-tools',
    name: 'admintool',
    component: AdminTool
  },
  {
    path: '/extras',
    name: 'extras',
    component: Extras
  },
  {
    path: '/message-tracker',
    name: 'messagetracker',
    component: MessageTracker
  },
  {
    path: '/setup',
    name: 'setup',
    component: Setup
  },

];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: "active",
});

export default router;
