export const GetDashboardData = () => {
    var response = {
        "message": "",
        "returncode": 0,
        "orgs_locations_menu": {
            "display": true,
            "count": 2,
            "list": {
                "CS Test Church": [
                    {
                        "organization_name": "CS Test Church",
                        "organization_id": "2",
                        "location_name": "Chicago main Branch",
                        "location_id": "2"
                    },
                    {
                        "organization_name": "CS Test Church",
                        "organization_id": "2",
                        "location_name": "Alpharetta Branch",
                        "location_id": "2"
                    }
                ]
            }
        },
        "cardslist": [
            {
                "id": "usermenu",
                "label": "User Menu",
                "role": "user",
                "sequence": 3
            },
            {
                "id": "myorganizations",
                "label": "My Organizations",
                "role": "admin",
                "sequence": 16
            },
            {
                "id": "adminmenu",
                "label": "Admin Menu",
                "role": "admin",
                "sequence": 12
            },
            {
                "id": "donate",
                "label": "Donate",
                "role": "admin",
                "sequence": 20
            },
            {
                "id": "setupwizard",
                "label": "Setup Wizard",
                "role": "admin",
                "sequence": 13,
                "showorgwizard": true,
                "showactivitywizard": true
            },
            {
                "id": "messagetracker",
                "label": "Message Tracker",
                "role": "admin",
                "sequence": 15
            },
            {
                "id": "status",
                "label": "Status",
                "role": "user",
                "sequence": 6,
                "notifications_enabled": true,
                "notifications_enabled_message": "e-mail, sms notifications are enabled",
                "location_status": true,
                "location_status_message": "",
                "org_status": true,
                "org_status_message": ""
            },
            {
                "id": "userassignments",
                "label": "Assignments/Schedules",
                "role": "user",
                "sequence": 7
            },
            {
                "id": "allschedules",
                "label": "View All Schedules",
                "role": "user",
                "sequence": 5
            },
            {
                "id": "superadminmenu",
                "label": "Super Admin Menu",
                "role": "superadmin",
                "sequence": 11
            }
        ]
    }
    
    return response;
}

