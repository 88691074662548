export const GetAdminMenu = () => {
    var response = {
        "adminmenu": [
            {
                "label": "Manage Organization",
                "uri": "/Manageorganization/get/cmd/editorganization/locationid/2",
                "uri_type": "modal",
                "title": "update/modify Organization settings"
            },
            {
                "label": "Manage Location",
                "uri": "/Managelocation/get/cmd/editlocation/locationid/2",
                "uri_type": "modal",
                "title": "update/modify Location settings"
            },
            {
                "label": "Manage Schedules",
                "uri": "/Manageschedule/get/locationid/2",
                "uri_type": "modal",
                "title": "view/update Schedules/Assignments"
            },
            {
                "label": "Manage Activities/Events",
                "uri": "/Manageactivity/get/locationid/2/cmd/displayactivities",
                "uri_type": "modal",
                "title": "create/edit/delete Activities/Events"
            },
            {
                "label": "Manage Skills/Roles",
                "uri": "/Manageskill/get/locationid/2/cmd/displayskills",
                "uri_type": "modal",
                "title": "create/edit/delete Skills/Roles"
            },
            {
                "label": "Manage Users",
                "uri": "/Manageuser/get/locationid/2/cmd/displaypersons",
                "uri_type": "modal",
                "title": "add/edit Users"
            },
            {
                "label": "Messaging Center",
                "uri": "/Sendmessage/list/id/2",
                "uri_type": "modal",
                "title": "send messages to volunteers/users"
            },

            //extra json
            {
                "label": "Instructions for Admin",
                "uri": "/Sendmessage/list/id/2",
                "uri_type": "modal",
                "title": "send messages to volunteers/users"
            },
            {
                "label": "Pending User Notification",
                "uri": "/Sendmessage/list/id/2",
                "uri_type": "modal",
                "title": "send messages to volunteers/users"
            },
            {
                "label": "Message Tracker",
                "uri": "/Sendmessage/list/id/2",
                "uri_type": "modal",
                "title": "send messages to volunteers/users"
            },

        ],
        "mainmenu": [
            {
                "label": "HOME",
                "uri": "/Home/index",
                "uri_type": "page",
                "title": "click here to go to the Home/Main menu page",
                "has_submenu": false
            },
            {
                "label": "MY CALENDAR",
                "uri": "/Calendar/index",
                "uri_type": "page",
                "title": "click here to view your calendar",
                "has_submenu": false
            },
            {
                "label": "ADMIN MENU",
                "uri": "adminmenu",
                "title": "menu for administrators",
                "has_submenu": true
            },
            {
                "label": "REQUEST ASSISTANCE",
                "uri": "",
                "uri_type": "modal",
                "title": "click here to request assistance",
                "has_submenu": false
            },
            {
                "label": "HELP DOCS",
                "uri": "https://www.churchscheduling.com/knowledge-base/",
                "uri_type": "newtab",
                "title": "click here to go to the help documentation",
                "has_submenu": false
            },
            {
                "label": "LOG OUT",
                "uri": "/Personajax/post",
                "title": "log out of churchscheduling.com",
                "uri_type": "api",
                "uri_payload": {
                    "cmd": "logout"
                },
                "has_submenu": false
            }
        ],
        "usermenu": [
            {
                "label": "my Profile/Preferences",
                "uri": "/Manageuser/get/locationid/2/cmd/editperson/personid/3",
                "uri_type": "modal",
                "title": "user profile, preferences, and scheduling rules"
            },
            {
                "label": "my Assignments",
                "uri": "/public/MF/cs-mf-personschedule/?personid=3",
                "uri_type": "modal",
                "title": "view user schedule/assignments",
                "has_submenu": false
            },
            {
                "label": "Sign-up Form",
                "uri": "/Home/signup/locid/2",
                "uri_type": "modal",
                "title": "view assignments that can be picked up",
                "has_submenu": false
            },
            {
                "label": "All Schedules",
                "uri": "/Viewactivities/index/lid/2",
                "uri_type": "modal",
                "title": "view all location schedules",
                "has_submenu": false
            },
            {
                "label": "Instructions for User",
                "uri": "/Viewactivities/index/lid/2",
                "uri_type": "modal",
                "title": "view all location schedules",
                "has_submenu": false
            }
        ],
        "detail": {
            "showadminmenu": true,
            "admin_menu_label": "Admin Menu",
            "organizationadmin": true,
            "locationadmin": true,
            "profile_greeting": "Hello Edward",
            "profile_desc": "Logged in as Edward A.(edward.aifah@webitools.com)",
            "location_name": "Chicago main Branch"
        },

        // json for setup
        "setup": [
            {
                "label": "Instructions for Admin",
                "uri": "/Manageuser/get/locationid/2/cmd/editperson/personid/3",
                "uri_type": "modal",
                "title": "user profile, preferences, and scheduling rules"
            },
            {
                "label": "Instructions for User",
                "uri": "/public/MF/cs-mf-personschedule/?personid=3",
                "uri_type": "modal",
                "title": "view user schedule/assignments",
                "has_submenu": false
            },
            {
                "label": "Setup New Organization",
                "uri": "/Home/signup/locid/2",
                "uri_type": "modal",
                "title": "view assignments that can be picked up",
                "has_submenu": false
            },
            {
                "label": "Join Organization",
                "uri": "/Viewactivities/index/lid/2",
                "uri_type": "modal",
                "title": "view all location schedules",
                "has_submenu": false
            }
        ],

        // json for admin tools
        "admintools": [
            {
                "label": "View Logs",
                "uri": "/Manageuser/get/locationid/2/cmd/editperson/personid/3",
                "uri_type": "modal",
                "title": "user profile, preferences, and scheduling rules"
            },
            {
                "label": "View Organizations",
                "uri": "/public/MF/cs-mf-personschedule/?personid=3",
                "uri_type": "modal",
                "title": "view user schedule/assignments",
                "has_submenu": false
            },
            {
                "label": "Api List",
                "uri": "/Home/signup/locid/2",
                "uri_type": "modal",
                "title": "view assignments that can be picked up",
                "has_submenu": false
            }
        ],

    }

    return response
}


export const GetUserMenu = () => {
    var response

    return response
}