<template>
    <div class="row">
        <div class="col-12">
            <div class="card my-4">
                <div class="card-body p-4">
                    <slot name="content"></slot>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "ContentContainer"
}
</script>

