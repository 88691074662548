<template>
  <div class="py-4 container-fluid">
    <div class="row mb-4">
      <div class="col-lg-12 position-relative z-index-2">
        <div class="row">
          <div class="col-lg-3 col-md-6 col-sm-6">
            <mini-statistics-card :title="{ text: 'Pending Registration', value: '3,320' }"
              detail="<span class='text-success text-sm font-weight-bolder'></span> Some Text Here" :icon="{
                name: 'weekend',
                color: 'text-white',
                background: 'dark',
              }" />
          </div>
          <div class="col-lg-3 col-md-6 col-sm-6 mt-lg-0 mt-4">
            <mini-statistics-card :title="{ text: 'No. of Users', value: '2,300' }"
              detail="<span class='text-success text-sm font-weight-bolder'></span> Some Text Here" :icon="{
                name: 'leaderboard',
                color: 'text-white',
                background: 'primary',
              }" />
          </div>
          <div class="col-lg-3 col-md-6 col-sm-6 mt-lg-0 mt-4">
            <mini-statistics-card :title="{ text: 'Scheduling Minutes', value: '3,462' }"
              detail="<span class='text-success text-sm font-weight-bolder'></span> Some Text Here" :icon="{
                name: 'person',
                color: 'text-white',
                background: 'success',
              }" />
          </div>
          <div class="col-lg-3 col-md-6 col-sm-6 mt-lg-0 mt-4">
            <mini-statistics-card :title="{ text: 'Open Assignments', value: '100' }"
              detail="<span class='text-success text-sm font-weight-bolder'></span> Some Text Here" :icon="{
                name: 'weekend',
                color: 'text-white',
                background: 'info',
              }" />
          </div>
        </div>
        <div class="row mt-4">
          <div class="col-lg-4 col-md-6 mt-4">
            <chart-holder-card title="Email & SMS count" subtitle="Some Text Here" update="Some Text Here">
              <reports-bar-chart :chart="{
                labels: ['M', 'T', 'W', 'T', 'F', 'S', 'S'],
                datasets: {
                  label: 'Email',
                  data: [50, 20, 10, 22, 50, 10, 40],

                  label2: 'SMS',
                  data2: [10, 40, 30, 12, 60, 30, 50],
                }
              }" />
            </chart-holder-card>
          </div>
          <div class="col-lg-4 col-md-6 mt-4">
            <chart-holder-card title="No. of Activities" subtitle="Some Text Here" update="Some Text Here"
              color="success">
              <reports-line-chart :chart="{
                labels: [
                  'Apr',
                  'May',
                  'Jun',
                  'Jul',
                  'Aug',
                  'Sep',
                  'Oct',
                  'Nov',
                  'Dec',
                ],
                datasets: {
                  label: 'Mobile apps',
                  data: [50, 40, 300, 320, 500, 350, 200, 230, 500],
                },
              }" />
            </chart-holder-card>
          </div>
          <div class="col-lg-4 mt-4">
            <chart-holder-card title="Completed Tasks" subtitle="Last Campaign Performance" update="just updated"
              color="dark">
              <reports-line-chart id="tasks-chart" :chart="{
                labels: [
                  'Apr',
                  'May',
                  'Jun',
                  'Jul',
                  'Aug',
                  'Sep',
                  'Oct',
                  'Nov',
                  'Dec',
                ],
                datasets: {
                  label: 'Mobile apps',
                  data: [50, 40, 300, 220, 500, 250, 400, 230, 500],
                },
              }" />
            </chart-holder-card>
          </div>
        </div>
      </div>
    </div>

    <ContentContainer>
      <template v-slot:content>
        <!-- <div class="row d-flex justify-content-center gap-1"> -->
        <div class="row d-flex m-auto">
          <div v-for="item in menuData.adminmenu" :key="item.label" class="col-lg-4 col-md-5 col-12">
            <TabCard :text="item.label" @click="openModal" />
          </div>
        </div>

        <Modal v-if="isModalOpen" :modalHeading="modalHeading" @close="closeModal">
        </Modal>
      </template>
    </ContentContainer>

    <!-- <div class="row">
      <div class="col-lg-8 col-md-6 mb-md-0 mb-4">
        <project-card
          title="Projects"
          description="<i class='fa fa-check text-info' aria-hidden='true'></i> <span class='font-weight-bold ms-1'>30 done</span> this month"
          :headers="['Companies', 'Members', 'Budget', 'Progress']"
          :projects="[
            {
              logo: logoXD,
              title: 'Material XD Material XD Version',
              members: [team1, team2, team3, team4],
              budget: '$14,000',
              progress: { percentage: 60, color: 'info' },
            },
            {
              logo: logoAtlassian,
              title: 'Add Progress Track',
              members: [team2, team4],
              budget: '$3,000',
              progress: { percentage: 10, color: 'info' },
            },
            {
              logo: logoSlack,
              title: 'Fix Platform Errors',
              members: [team3, team1],
              budget: 'Not set',
              progress: { percentage: 100, color: 'success' },
            },
            {
              logo: logoSpotify,
              title: 'Launch our Mobile App',
              members: [team4, team3, team4, team1],
              budget: '$20,500',
              progress: { percentage: 100, color: 'success' },
            },
            {
              logo: logoJira,
              title: 'Add the New Pricing Page',
              members: [team4],
              budget: '$500',
              progress: { percentage: 25, color: 'info' },
            },
            {
              logo: logoJira,
              title: 'Redesign New Online Shop',
              members: [team1, team4],
              budget: '$2,000',
              progress: { percentage: 40, color: 'info' },
            },
          ]"
        />
      </div>
      <div class="col-lg-4 col-md-6">
        <timeline-list
          class="h-100"
          title="Orders overview"
          description="<i class='fa fa-arrow-up text-success' aria-hidden='true'></i>
        <span class='font-weight-bold'>24%</span> this month"
        >
          <timeline-item
            :icon="{
              component: 'notifications',
              class: 'text-success',
            }"
            title="$2400 Design changes"
            date-time="22 DEC 7:20 PM"
          />
          <TimelineItem
            :icon="{
              component: 'code',
              class: 'text-danger',
            }"
            title="New order #1832412"
            date-time="21 DEC 11 PM"
          />
          <TimelineItem
            :icon="{
              component: 'shopping_cart',
              class: 'text-info',
            }"
            title="Server payments for April"
            date-time="21 DEC 9:34 PM"
          />
          <TimelineItem
            :icon="{
              component: 'credit_card',
              class: 'text-warning',
            }"
            title="New card added for order #4395133"
            date-time="20 DEC 2:20 AM"
          />
          <TimelineItem
            :icon="{
              component: 'vpn_key',
              class: 'text-primary',
            }"
            title="Unlock packages for development"
            date-time="18 DEC 4:54 AM"
            class="pb-1"
          />
        </timeline-list>
      </div>
    </div> -->
  </div>
</template>
<script>
import ChartHolderCard from "./components/ChartHolderCard.vue";
import ReportsBarChart from "@/components/Charts/ReportsBarChart.vue";
import ReportsLineChart from "@/components/Charts/ReportsLineChart.vue";
import MiniStatisticsCard from "./components/MiniStatisticsCard.vue";
// import ProjectCard from "./components/ProjectCard.vue";
// import TimelineList from "@/components/Cards/TimelineList.vue";
// import TimelineItem from "@/components/Cards/TimelineItem.vue";
import logoXD from "@/assets/img/small-logos/logo-xd.svg";
import logoAtlassian from "@/assets/img/small-logos/logo-atlassian.svg";
import logoSlack from "@/assets/img/small-logos/logo-slack.svg";
import logoSpotify from "@/assets/img/small-logos/logo-spotify.svg";
import logoJira from "@/assets/img/small-logos/logo-jira.svg";
import logoInvision from "@/assets/img/small-logos/logo-invision.svg";
import team1 from "@/assets/img/team-1.jpg";
import team2 from "@/assets/img/team-2.jpg";
import team3 from "@/assets/img/team-3.jpg";
import team4 from "@/assets/img/team-4.jpg";
import Modal from '@/components/Modal/Modal.vue';
import ContentContainer from '@/components/PageLayout/ContentContainer.vue';
import TabCard from '@/components/Cards/TabCards.vue';
import { mapState } from 'vuex';

export default {
  name: "dashboard-default",
  data() {
    return {
      logoXD,
      team1,
      team2,
      team3,
      team4,
      logoAtlassian,
      logoSlack,
      logoSpotify,
      logoJira,
      logoInvision,
      isModalOpen: false,
      modalHeading: "",
    };
  },
  components: {
    ChartHolderCard,
    ReportsBarChart,
    ReportsLineChart,
    MiniStatisticsCard,
    ContentContainer,
    TabCard,
    Modal
    // ProjectCard,
    // TimelineList,
    // TimelineItem,
  },
  computed: {
        ...mapState([
            "menuData"
        ]),
    },
    methods: {
        openModal() {
            this.isModalOpen = true;
        },
        closeModal() {
            this.isModalOpen = false;
        },
    },
};
</script>
